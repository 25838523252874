import { FullAddress } from 'apiHelpers/address/address';
import { CustomerDetails } from 'state/formData/customerDetails';
import { ReferenceDataOption } from 'types/referenceData';

export const fullAddressToString = (address: FullAddress): string => {
  // Convention dictates building number and street name should be 1 line
  const houseNumberAndStreet = address.houseNumber
    ? `${address.houseNumber} ${address.street}`
    : address.street;

  return [
    address.flatNumber,
    address.flatName,
    address.houseName,
    houseNumberAndStreet,
    address.town,
  ]
    .filter(Boolean)
    .join(', ');
};

const formatPostcode = (postcode: string): string =>
  postcode.replace(/(\S*)\s*(\d)/, '$1 $2');

export const manualAddressToString = (
  customerDetails: CustomerDetails,
  countiesRefData: ReferenceDataOption[]
): string => {
  return [
    customerDetails.flatNameOrNumber,
    customerDetails.houseNameOrNumber,
    customerDetails.street,
    customerDetails.town,
    countiesRefData.find((county) => county.value === customerDetails.county)?.name ?? '',
    formatPostcode(customerDetails.postcode),
  ]
    .filter(Boolean)
    .join(', ');
};
