import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { graphql, useStaticQuery } from 'gatsby';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
} from 'helpers/referenceDataConstants';

type CsCatBreedTypeOptions = {
  csPetAboutYourPetCatQuestions: {
    cat_breed_type: {
      option_texts: {
        pedigree: string;
        non_pedigree: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetCatQuestions {
      cat_breed_type {
        option_texts {
          pedigree
          non_pedigree
        }
      }
    }
  }
`;

export const useCatBreedTypeOptions = (): Option[] => {
  const catBreedTypeOptions = useStaticQuery<CsCatBreedTypeOptions>(query)
    .csPetAboutYourPetCatQuestions.cat_breed_type.option_texts;
  return [
    { value: catBreedType_PEDIGREE, name: catBreedTypeOptions.pedigree },
    { value: catBreedType_NON_PEDIGREE, name: catBreedTypeOptions.non_pedigree },
  ];
};
