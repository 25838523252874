import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';

export type KeepingYouInformedQuestions = {
  keepingYouInformed: Question;
};

type csPetKeepingYouInformedQuestions = {
  csPetKeepingYouInformedQuestions: {
    keep_informed_question: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetKeepingYouInformedQuestions {
      keep_informed_question {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useKeepingYouInformedQuestions = (): KeepingYouInformedQuestions => {
  const csQuestions = useStaticQuery<csPetKeepingYouInformedQuestions>(query);
  const { processQuestion } = useQuestionProcessor(PageTitle.AboutYouAndYourPet);

  return {
    keepingYouInformed: processQuestion(
      csQuestions.csPetKeepingYouInformedQuestions.keep_informed_question
    ),
  };
};

export default useKeepingYouInformedQuestions;
