import React, { Fragment, MouseEventHandler } from 'react';
import {
  DetailHeading,
  DetailLabel,
  DetailSpanButton,
  DetailSpanHeading,
  DetailValue,
  DetailWrapper,
  EditButtonIcon,
  EditButtonLink,
  LabelExplanation,
  LabelExplanationWrapper,
  ListItemWithMargins,
  SectionWithBorder,
  UnorderedListWithoutBullets,
} from './styles';

type DetailGroupSectionProps = {
  sectionHeading: string;
  sectionId: string;
  details: Detail[];
  editButtonLabel: string;
  editButtonScreenreaderText: string;
  editButtonOnClick: MouseEventHandler;
  piiOptions?: PiiOptions;
};

export type Detail = {
  label: string;
  explanationText?: string;
  value: string;
  childAfter?: React.ReactNode;
};

export type PiiOptions = {
  headingIsPii?: boolean;
  detailLabelsArePii?: boolean;
  detailValuesArePii?: boolean;
};

const DetailGroupSection: React.FC<DetailGroupSectionProps> = ({
  details,
  editButtonOnClick,
  editButtonLabel,
  editButtonScreenreaderText,
  sectionHeading,
  sectionId,
  piiOptions,
}) => {
  return (
    <SectionWithBorder aria-labelledby={sectionId}>
      <DetailWrapper>
        <DetailSpanHeading>
          {piiOptions?.headingIsPii ? (
            <DetailHeading id={sectionId} data-cs-mask={piiOptions?.headingIsPii}>
              {sectionHeading}
            </DetailHeading>
          ) : (
            <DetailHeading id={sectionId}>{sectionHeading}</DetailHeading>
          )}
        </DetailSpanHeading>
      </DetailWrapper>
      <UnorderedListWithoutBullets>
        {details.map((detail) => (
          <Fragment key={detail.label}>
            <ListItemWithMargins>
              <DetailLabel>{detail.label}</DetailLabel>
              {piiOptions?.detailValuesArePii ? (
                <DetailValue data-cs-mask={piiOptions?.detailValuesArePii}>
                  {detail.value}
                </DetailValue>
              ) : (
                <DetailValue>{detail.value}</DetailValue>
              )}
              {detail.explanationText && (
                <LabelExplanationWrapper>
                  <LabelExplanation html={detail.explanationText} />
                </LabelExplanationWrapper>
              )}
              {detail?.childAfter}
            </ListItemWithMargins>
          </Fragment>
        ))}
      </UnorderedListWithoutBullets>
      <DetailSpanButton>
        <EditButtonLink
          id={`${sectionId}-edit-button`}
          aria-label={editButtonScreenreaderText}
          onClick={editButtonOnClick}>
          <EditButtonIcon name="edit" size="small" />
          {editButtonLabel}
        </EditButtonLink>
      </DetailSpanButton>
    </SectionWithBorder>
  );
};

export default DetailGroupSection;
