import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React, { Fragment } from 'react';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import CheckContactDetailsForm from 'forms/CheckContactDetailsForm';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import DetailGroupSection from './DetailGroupSection';
import useAboutYouSection from './useAboutYouSection';
import useAdditionalInformationSection from './useAdditionalInformationSection';
import useCoverDatesSection from './useCoverDatesSection';
import useCoverLevelSection from './useCoverLevelSection';
import useExcessSections from './useExcessSections';
import useJointPolicyholderSection from './useJointPolicyholderSection';
import usePetSections from './usePetSections';

type DetailsSectionProps = {
  currentQuote: CurrentQuote;
  getError: FieldFunction<FormDetails, string | undefined>;
  showValidation: FieldFunction<FormDetails, void>;
};

type DetailsSectionCsProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    about_you: {
      about_you_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_joint_policyholder: {
      about_your_joint_policyholder_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_pet: {
      about_your_pet_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    additional_information: {
      additional_information_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    excess: {
      excess_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_dates: {
      your_cover_dates_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_level: {
      your_cover_level_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      about_you {
        about_you_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_joint_policyholder {
        about_your_joint_policyholder_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_pet {
        about_your_pet_heading
        edit_button_text
        edit_button_screenreader_text
      }
      additional_information {
        additional_information_heading
        edit_button_text
        edit_button_screenreader_text
      }
      excess {
        excess_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_dates {
        your_cover_dates_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_level {
        your_cover_level_heading
        edit_button_text
        edit_button_screenreader_text
      }
    }
  }
`;

const DetailsSection: React.FC<DetailsSectionProps> = ({
  currentQuote,
  getError,
  showValidation,
}) => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      about_you,
      about_your_joint_policyholder,
      about_your_pet,
      additional_information,
      excess,
      your_cover_dates,
      your_cover_level,
    },
  } = useStaticQuery<DetailsSectionCsProps>(query);

  const yourCoverDatesSectionId = 'your-cover-dates-section';
  const yourCoverLevelSectionId = 'your-cover-level-section';
  const aboutYouSectionId = 'about-you-section';
  const yourContactDetailsSectionId = 'your-contact-details-section';
  const aboutYourJointPolicyholderSectionId = 'about-your-joint-policyholder-section';
  const aboutYourPetSectionId = 'about-your-pet-section';
  const additionalInformationSectionId = 'additional-information-section';
  const excessSectionId = 'excess-section';

  const jointPolicyholderDetails = useJointPolicyholderSection();
  const petsDetails = usePetSections();
  const petsExcess = useExcessSections();

  return (
    <>
      <DetailGroupSection
        sectionHeading={your_cover_dates.your_cover_dates_heading}
        sectionId={yourCoverDatesSectionId}
        details={useCoverDatesSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit cover dates');
          navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#about-your-policy-section`);
        }}
        editButtonLabel={your_cover_dates.edit_button_text}
        editButtonScreenreaderText={your_cover_dates.edit_button_screenreader_text}
      />
      <DetailGroupSection
        sectionHeading={your_cover_level.your_cover_level_heading}
        sectionId={yourCoverLevelSectionId}
        details={useCoverLevelSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit cover level');
          navigate(`${quoteAndBuyRoutes.quoteSummary}`);
        }}
        editButtonLabel={your_cover_level.edit_button_text}
        editButtonScreenreaderText={your_cover_level.edit_button_screenreader_text}
      />
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // Index is the unique identifier for pet info
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              trimEnd(petInfo.petName),
              excess.excess_heading
            )}
            sectionId={`${excessSectionId}[${index}]`}
            details={petsExcess[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit pet excess');
              navigate(`${quoteAndBuyRoutes.quoteSummary}#petExcess[${index}]-container`);
            }}
            editButtonLabel={petNameReplacer(
              trimEnd(petInfo.petName),
              excess.edit_button_text
            )}
            editButtonScreenreaderText={excess.edit_button_screenreader_text}
            piiOptions={{ headingIsPii: false, detailLabelsArePii: false }}
          />
        </Fragment>
      ))}
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              petInfo.petName,
              about_your_pet.about_your_pet_heading
            )}
            sectionId={`${aboutYourPetSectionId}[${index}]`}
            details={petsDetails[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'edit pet details');
              navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#petDetails[${index}]`);
            }}
            editButtonLabel={petNameReplacer(
              trimEnd(petInfo.petName),
              about_your_pet.edit_button_text
            )}
            editButtonScreenreaderText={about_your_pet.edit_button_screenreader_text}
            piiOptions={{ headingIsPii: false, detailLabelsArePii: false }}
          />
        </Fragment>
      ))}
      <DetailGroupSection
        sectionHeading={about_you.about_you_heading}
        sectionId={aboutYouSectionId}
        details={useAboutYouSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit about you');
          navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#about-you-section`);
        }}
        editButtonLabel={about_you.edit_button_text}
        editButtonScreenreaderText={about_you.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: true }}
      />
      <CheckContactDetailsForm
        getError={getError}
        showValidation={showValidation}
        sectionId={yourContactDetailsSectionId}
      />
      {currentQuote.policyInfo?.includeJoinPolicyHolder && (
        <DetailGroupSection
          sectionHeading={
            about_your_joint_policyholder.about_your_joint_policyholder_heading
          }
          sectionId={aboutYourJointPolicyholderSectionId}
          details={jointPolicyholderDetails}
          editButtonOnClick={() => {
            trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit joint policyholder');
            navigate(
              `${quoteAndBuyRoutes.aboutYouAndYourPet}#joint-policyholder-section`
            );
          }}
          editButtonLabel={about_your_joint_policyholder.edit_button_text}
          editButtonScreenreaderText={
            about_your_joint_policyholder.edit_button_screenreader_text
          }
          piiOptions={{ detailValuesArePii: true }}
        />
      )}
      <DetailGroupSection
        sectionHeading={additional_information.additional_information_heading}
        sectionId={additionalInformationSectionId}
        details={useAdditionalInformationSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit additional information');
          navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#numberOfPetsInHousehold`);
        }}
        editButtonLabel={additional_information.edit_button_text}
        editButtonScreenreaderText={additional_information.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: false }}
      />
    </>
  );
};

export default DetailsSection;
