import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import { graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import AggregatorAssumptionsSection from 'components/CheckYourDetails/AggregatorAssumptionsSection';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import useAggregatorAssumptionsRules from 'components/CheckYourDetails/AggregatorAssumptionsSection/validation';
import DeclarationSection from 'components/CheckYourDetails/DeclarationSection';
import DetailsSection from 'components/CheckYourDetails/DetailsSection';
import useQuoteReference from 'components/CheckYourDetails/DetailsSection/useQuoteReference';
import { useCheckYourDetailsPdf } from 'components/CheckYourDetailsPdf/useCheckYourDetailsPdf';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import PolicyDocumentsSection from 'components/Payment/PolicyDocumentsSection';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { useContactDetailsRules } from 'forms/CheckContactDetailsForm/validation';
import KeepingYouInformedForm from 'forms/KeepingYouInformedForm';
import useKeepingYouInformedRules from 'forms/KeepingYouInformedForm/validation';
import SectionHeading from 'forms/SectionHeading';
import { ProductId } from 'helpers/businessConstants';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { PageTitle, trackFieldError, trackTextButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { usePageTracking } from 'helpers/pageTracking';
import { isQuoteSummaryDetailsInvalid } from 'helpers/quoteAndBuyPageFlowHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  storeData,
} from 'helpers/sessionStorageHelpers';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDeclarations from 'helpers/useDeclarations';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { CsHero } from 'types/contentStack';
import {
  BoldParagraphLargeSpan,
  EditInfoText,
  ImageWithFixedHeight,
  ParagraphLargeSpan,
  QuoteReferenceWrapper,
  SectionWithBorder,
  StyledDeclarationSectionContainer,
} from './styles';

const PAGE_LANDING_STEP = 2;

type CheckYourDetailsProps = {
  data: {
    csPetCheckYourDetails: {
      meta_title: string;
      hero: CsHero;
      edit_info_intro_text: string;
      next_button_text: string;
    };
    csPetAboutYouAndYourPet: {
      keeping_you_informed_section_heading: string;
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetails {
      meta_title
      hero {
        heading
        subheading
      }
      edit_info_intro_text
      next_button_text
    }
    csPetAboutYouAndYourPet {
      keeping_you_informed_section_heading
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
      }
    }
  }
`;

const CheckYourDetails: React.FC<CheckYourDetailsProps> = ({
  data: {
    csPetCheckYourDetails: { hero, next_button_text, meta_title, edit_info_intro_text },
    csPetAboutYouAndYourPet: { keeping_you_informed_section_heading },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text },
    },
  },
}) => {
  const aggregatorAssumptionsSectionId = 'aggregator-assumptions-section';
  const declarationSectionId = 'declaration-section';
  const keepingYouInformedSectionId = 'keeping-you-informed-section';

  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const isAggsQuote = quote.productId !== ProductId.DIRECT;

  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();

  if (quote.petInfos) {
    if (isQuoteSummaryDetailsInvalid(quote.quoteOptions)) {
      navigate(quoteAndBuyRoutes.quoteSummary);
    }
  }

  const contactDetailsRules = useContactDetailsRules();

  const {
    declarations,
    updateDeclarationAgreement,
    declarationRules,
  } = useDeclarations();

  const aggregatorBrandDetails = useAggregatorBrandDetails(quote.productId);

  const aggregatorAssumptionRules = useAggregatorAssumptionsRules();
  const keepInformedRules = useKeepingYouInformedRules();

  const [assumptionsAgreement] = useAssumptionsAgreement();

  const rules = {
    ...contactDetailsRules,
    ...declarationRules,
    ...(isAggsQuote ? aggregatorAssumptionRules : {}),
    ...(isAggsQuote ? keepInformedRules : {}),
  };

  const { getError, validateOnSubmit, showValidation } = useValidation(
    {
      ...customerDetails,
      declarations,
      ...assumptionsAgreement,
      keepInformed: policyDetails.keepInformed,
    },
    rules,
    trackFieldError
  );

  const assumptions = useAssumptions();

  /*  We only display the aggregator assumptions section if some
   *  assumptions exist and the assumptions have not yet been agreed
   *  to when the page is first rendered, so we must record the assumptionsAgreed value on render.
   */
  const [assumptionsAgreedOnRender] = useState(assumptionsAgreement.assumptionsAgreed);

  const {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    isSaveInProgress,
  } = useSaveAndEmailQuote(PageTitle.CheckYourDetails);

  const { createQuote: requote, isLoading: isRequoting } = useGenerateQuote();

  const moveNext = async (): Promise<void> => {
    if (
      policyDetails.keepInformed !== quote?.policyInfo?.contactable ||
      customerDetails.customerEmail !== quote.customerInfo?.email ||
      customerDetails.customerTelephone !== quote.customerInfo?.contactPhoneNumber
    ) {
      await requote();
    }
    navigate(quoteAndBuyRoutes.payment, {
      state: { detailsValid: true },
    });
  };

  const showAssumptions = !!assumptions && !assumptionsAgreedOnRender;
  const generatePdf = useCheckYourDetailsPdf(
    quote,
    declarations,
    assumptionsAgreement,
    policyDetails.keepInformed,
    showAssumptions
  );

  usePageTracking(meta_title, !!quote.customerInfo);
  useCheckoutTracking(PAGE_LANDING_STEP, quote, true);

  const quoteReference = useQuoteReference();

  return (
    <LoadQuoteWrapper>
      <Layout
        currentStep={QuoteAndBuyStep.CheckDetails}
        pageTitle={PageTitle.CheckYourDetails}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        <Grid alignLeft>
          <GridItem desktop={8} tabletLandscape={10}>
            {isRequoting && (
              <LoadingOverlayV2
                loadingMessage="Updating preferences, please wait"
                timeDuration={10}
              />
            )}
            {isSaveInProgress && (
              <LoadingOverlayV2 loadingMessage={email_spinner_text} timeDuration={10} />
            )}
            <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
              <EditInfoText
                pageTitle={PageTitle.CheckYourDetails}
                html={edit_info_intro_text}
              />
              <QuoteReferenceWrapper>
                <ParagraphLargeSpan>{quoteReference.label} </ParagraphLargeSpan>
                <BoldParagraphLargeSpan>{quoteReference.value}</BoldParagraphLargeSpan>
              </QuoteReferenceWrapper>
              {quote.productId !== ProductId.DIRECT &&
                (showAssumptions ? (
                  <SectionWithBorder aria-labelledby={aggregatorAssumptionsSectionId}>
                    <AggregatorAssumptionsSection
                      sectionHeadingId={aggregatorAssumptionsSectionId}
                      getError={getError}
                      showValidation={showValidation}
                    />
                  </SectionWithBorder>
                ) : (
                  aggregatorBrandDetails?.logo && (
                    <Grid data-cy="aggregator-logo" alignLeft>
                      <GridItem>
                        <ImageWithFixedHeight image={aggregatorBrandDetails.logo} />
                      </GridItem>
                    </Grid>
                  )
                ))}
              <DetailsSection
                currentQuote={quote}
                getError={getError}
                showValidation={showValidation}
              />
              <PricingOverviewPanel
                pageTitle={PageTitle.CheckYourDetails}
                gridItemProps={{ desktop: 8, tabletLandscape: 10 }}
              />
              <StyledDeclarationSectionContainer aria-labelledby={declarationSectionId}>
                <DeclarationSection
                  sectionHeadingId={declarationSectionId}
                  declarations={declarations}
                  updateHasAgreed={(update: boolean, index: number) => {
                    updateDeclarationAgreement(update, index);
                    showValidation('declarations', ['hasAgreed', index]);
                  }}
                  getError={getError}
                />
              </StyledDeclarationSectionContainer>
              <section>
                <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8} mobile={4}>
                  <PolicyDocumentsSection quoteOptions={quoteOptions} />
                </GridItem>
              </section>
              {isAggsQuote && (
                <section aria-labelledby={keepingYouInformedSectionId}>
                  <SectionHeading
                    heading={keeping_you_informed_section_heading}
                    id={keepingYouInformedSectionId}
                  />
                  <KeepingYouInformedForm formValidation={{ getError, showValidation }} />
                </section>
              )}
              <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8} mobile={4}>
                <FormFooter
                  backButton={{
                    onClick: () => {
                      trackTextButtonClick(PageTitle.CheckYourDetails, 'Back');
                      navigate(quoteAndBuyRoutes.quoteSummary);
                    },
                  }}
                  moveNextButton={{
                    text: next_button_text,
                    onClick: () => {
                      storeData(
                        PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
                        quote.quoteOptions.isAnnualPayment ? 'ANNUAL' : 'MONTHLY'
                      );
                      trackTextButtonClick(PageTitle.CheckYourDetails, 'Continue');
                    },
                  }}
                  downloadButton={{
                    onClick: generatePdf,
                  }}
                  saveButton={{
                    onClick: () => {
                      if (isQuoteOptionSelectionValid(quoteOptions)) {
                        saveAndEmailQuote();
                      }
                    },
                  }}
                  pageTitle={PageTitle.CheckYourDetails}
                />
              </GridItem>
              {savedQuoteConfirmationModal}
            </form>
          </GridItem>
        </Grid>
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default CheckYourDetails;
