import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { graphql, useStaticQuery } from 'gatsby';
import {
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
} from 'helpers/referenceDataConstants';

type CsDogBreedTypeOptions = {
  csPetAboutYourPetDogQuestions: {
    dog_breed_type: {
      option_texts: {
        pedigree: string;
        cross_breed: string;
        mixed_breed: string;
      };
    };
    pedigree_dog_breed_name: {
      error_messages: {
        no_matches_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetDogQuestions {
      dog_breed_type {
        option_texts {
          cross_breed
          mixed_breed
          pedigree
        }
      }
      pedigree_dog_breed_name {
        error_messages {
          no_matches_text
        }
      }
    }
  }
`;

export const useDogBreedTypeOptions = (): Option[] => {
  const dogBreedTypeOptions = useStaticQuery<CsDogBreedTypeOptions>(query)
    .csPetAboutYourPetDogQuestions.dog_breed_type.option_texts;
  return [
    { value: dogBreedType_PEDIGREE, name: dogBreedTypeOptions.pedigree },
    { value: dogBreedType_CROSS_BREED, name: dogBreedTypeOptions.cross_breed },
    { value: dogBreedType_MONGREL, name: dogBreedTypeOptions.mixed_breed },
  ];
};
export const useDogBreedNoMatchesText = (): string => {
  const dogBreedNoMatchText = useStaticQuery<CsDogBreedTypeOptions>(query)
    .csPetAboutYourPetDogQuestions.pedigree_dog_breed_name.error_messages.no_matches_text;
  return dogBreedNoMatchText;
};
