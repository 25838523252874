import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextLarge from 'components/RichTextLarge';

export const PrivacyNotice = styled(RichTextLarge)`
  margin-top: ${spacing(4)};
`;
export const KeepingYouInformedText = styled(RichTextLarge)`
  margin: 0 0 ${spacing(4)};
`;
