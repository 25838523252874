import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { PageTitle } from 'helpers/eventTracking';
import { useAboutYourPetPlaceholders } from 'helpers/placeholders/aboutYourPetPlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_PEDIGREE,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { Pet } from 'state/formData/petsDetails';
import { CsIcon } from 'types/contentStack';
import {
  InfoPanelWithPaddingAndLightBorder,
  StyledFlexibleWidthDivider,
  StyledInfoPanel,
  StyledPanelHeading,
  StyledPanelHeadingContainer,
  StyledRichTextWithModal,
} from './styles';
import { useCatEligibilityStatement } from '../../forms/AboutYourPetsForm/useCatEligibilityStatement';

type BreedInfoPanelData = {
  csPetAboutYourPetDogQuestions: {
    pedigree_dog_breed_name: {
      breed_info_panel_text: string;
    };
    cross_breed_dog_breed_name: {
      breed_info_panel_text: string;
    };
    mongrel_size: {
      breed_info_panel_text: string;
    };
  };
  csPetAboutYourPetCatQuestions: {
    pedigree_cat_breed_name: {
      breed_info_panel_text: string;
    };
    non_pedigree_cat_breed_name: {
      breed_info_panel_text: string;
    };
  };
  csPetCheckYourDetailsDetailsSectionV2: {
    about_your_pet: {
      statements_heading: string;
      statements: string;
      custom_bullet_icon: [CsIcon];
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPetDogQuestions {
      dog_eligibility_question {
        error_messages {
          missing
          dog_kickout_message
        }
      }
      pedigree_dog_breed_name {
        breed_info_panel_text
      }
      cross_breed_dog_breed_name {
        breed_info_panel_text
      }
      mongrel_size {
        breed_info_panel_text
      }
    }
    csPetAboutYourPetCatQuestions {
      cat_eligibility_question {
        error_messages {
          missing
          cat_kickout_message
        }
      }
      pedigree_cat_breed_name {
        breed_info_panel_text
      }
      non_pedigree_cat_breed_name {
        breed_info_panel_text
      }
    }
    csPetCheckYourDetailsDetailsSectionV2 {
      about_your_pet {
        statements_heading
        statements
        custom_bullet_icon {
          icon_code
        }
        statements
      }
    }
  }
`;

type BreedInfoPanelProps = {
  petDetails: Pet;
  pageTitle: PageTitle;
};

// To populate the breed playback panel we need the Pet information to be in a Pet object
// Here we will convert our QuoteResponsePetInfo to type Pet
// We are only populating the fields required for the breed playback panel, so be aware of this if re-using in future
export const convertQuoteResponsePetInfoToPetObject = (
  petInfo: QuoteResponsePetInfo
): Pet => {
  return {
    petName: petInfo.petName,
    petType: petInfo.petType,
    dogBreedType: petInfo.petBreedType,
    dogPedigreeBreedName: petInfo.petBreed,
    dogCrossBreedName: petInfo.petBreed,
    mongrelSize: petInfo.petBreedType,
    petIsEligible: undefined,
    dogLegalAction: undefined,
    dogComplaints: undefined,
    catBreedType: petInfo.petBreedType,
    catPedigreeBreedName: petInfo.petBreed,
    catNonPedigreeBreedName: petInfo.petBreed,
    indoorCat: petInfo.isIndoorPet,
    petGender: petInfo.petGender,
    petCost: undefined,
    petSpayed: '',
    petChipped: '',
    petLivesWithYou: undefined,
    petForMakeMoney: undefined,
    dogBreedListLink: undefined,
    petInGoodHealth: undefined,
    petDob: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  };
};

const BreedPlaybackPanel: React.FC<BreedInfoPanelProps> = ({ petDetails, pageTitle }) => {
  const {
    csPetAboutYourPetDogQuestions: {
      pedigree_dog_breed_name,
      cross_breed_dog_breed_name,
      mongrel_size,
    },
    csPetAboutYourPetCatQuestions: {
      pedigree_cat_breed_name,
      non_pedigree_cat_breed_name,
    },
    csPetCheckYourDetailsDetailsSectionV2: { about_your_pet },
  } = useStaticQuery<BreedInfoPanelData>(query);
  const petIsDog = petDetails.petType === petType_DOG;
  const petIsCat = petDetails.petType === petType_CAT;
  const catEligibilityStatement = useCatEligibilityStatement();

  const showPedigreeDogBreedNameInfoPanel =
    petIsDog && petDetails.dogBreedType === dogBreedType_PEDIGREE;
  const showCrossBreedDogBreedNameInfoPanel =
    petIsDog && petDetails.dogBreedType === dogBreedType_CROSS_BREED;
  const showPedigreeCatBreedNameInfoPanel =
    petIsCat && petDetails.catBreedType === catBreedType_PEDIGREE;
  const showNonPedigreeCatBreedNameInfoPanel =
    petIsCat && petDetails.catBreedType === catBreedType_NON_PEDIGREE;

  const aboutYourPetPlaceholders = useAboutYourPetPlaceholders();
  const substitutePetPlaceholders = replacePlaceholdersRichText(
    aboutYourPetPlaceholders,
    petDetails
  );

  const getBreedInfoPanelText = (): string => {
    if (showPedigreeCatBreedNameInfoPanel) {
      return substitutePetPlaceholders(pedigree_cat_breed_name.breed_info_panel_text);
    }

    if (showNonPedigreeCatBreedNameInfoPanel) {
      return substitutePetPlaceholders(non_pedigree_cat_breed_name.breed_info_panel_text);
    }

    if (showPedigreeDogBreedNameInfoPanel) {
      return substitutePetPlaceholders(pedigree_dog_breed_name.breed_info_panel_text);
    }

    if (showCrossBreedDogBreedNameInfoPanel) {
      return substitutePetPlaceholders(cross_breed_dog_breed_name.breed_info_panel_text);
    }

    return substitutePetPlaceholders(mongrel_size.breed_info_panel_text);
  };

  const getPetDogQuestionsStatementList = (): string => {
    return substitutePetPlaceholders(about_your_pet.statements);
  };

  const getPetCatQuestionsStatementList = (): string => {
    return substitutePetPlaceholders(catEligibilityStatement.statement_list);
  };
  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();
  const [assumptionsAgreedOnRender] = useState(assumptionsAgreement.assumptionsAgreed);
  const showAssumptions = !!assumptions && !assumptionsAgreedOnRender;

  return (
    <div>
      <FieldGrid alignLeft>
        <InfoPanelWithPaddingAndLightBorder
          data-cy="pet-breed-info-panel"
          isCheckDetailsPage={pageTitle === PageTitle.CheckYourDetails}>
          <StyledRichTextWithModal html={getBreedInfoPanelText()} pageTitle={pageTitle} />
        </InfoPanelWithPaddingAndLightBorder>
      </FieldGrid>
      {pageTitle === PageTitle.CheckYourDetails && !showAssumptions && (
        <>
          <StyledFlexibleWidthDivider>
            <FlexibleWidthDivider aria-hidden />
          </StyledFlexibleWidthDivider>
          <FieldGrid alignLeft>
            <StyledInfoPanel
              data-cy="pet-questions-statement-list"
              isCheckDetailsPage={pageTitle === PageTitle.CheckYourDetails}>
              <Grid alignLeft>
                <StyledPanelHeadingContainer>
                  <StyledPanelHeading>
                    {about_your_pet.statements_heading}
                  </StyledPanelHeading>
                </StyledPanelHeadingContainer>
                <StyledRichTextWithModal
                  html={
                    petIsDog
                      ? getPetDogQuestionsStatementList()
                      : getPetCatQuestionsStatementList()
                  }
                  pageTitle={pageTitle}
                />
              </Grid>
            </StyledInfoPanel>
          </FieldGrid>
        </>
      )}
    </div>
  );
};

export default BreedPlaybackPanel;
