import {
  catBreedType_PEDIGREE,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import { Pet } from 'state/formData/petsDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { useDefaultPetNamePlaceholder } from './dynamicPetNameHelpers';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const useAboutYourPetPlaceholders = (): CsPlaceholders<Pet> => {
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const defaultPetNameText = useDefaultPetNamePlaceholder();

  const getReadableMongrelSize = (mongrelSize: string): string => {
    switch (mongrelSize) {
      case mongrelSize_SMALL:
        return 'small';
      case mongrelSize_MEDIUM:
        return 'medium';
      case mongrelSize_LARGE:
        return 'large';
      default:
        return '';
    }
  };
  const getPetBreedName = (pet: Pet): string => {
    if (pet.petType === petType_DOG) {
      if (pet.dogBreedType === dogBreedType_MONGREL) return '';

      const dogBreed =
        pet.dogBreedType === dogBreedType_PEDIGREE
          ? pet.dogPedigreeBreedName
          : pet.dogCrossBreedName;

      return dogBreedsRefData.find((breed) => breed.value === dogBreed)?.name ?? '';
    }

    const catBreed =
      pet.catBreedType === catBreedType_PEDIGREE
        ? pet.catPedigreeBreedName
        : pet.catNonPedigreeBreedName;
    return catBreedsRefData.find((breed) => breed.value === catBreed)?.name ?? '';
  };
  return {
    petName: {
      getSubstitutionText: (pet) =>
        pet.petName.length > 0
          ? capitaliseFirstCharacter(pet.petName)
          : capitaliseFirstCharacter(defaultPetNameText),
      isPii: false,
    },
    petBreedName: {
      getSubstitutionText: (pet) => getPetBreedName(pet),
      isPii: false,
    },
    petMongrelSize: {
      getSubstitutionText: (pet) => getReadableMongrelSize(pet.mongrelSize),
      isPii: false,
    },
  };
};
