import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { borderPx } from '@rsa-digital/evo-shared-components/components/Panel/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';

export const InfoPanelWithPaddingAndLightBorder = styled(InfoPanel)<{
  isCheckDetailsPage: boolean;
}>`
  padding: ${spacing(3)};
  margin-top: ${({ isCheckDetailsPage }) => (isCheckDetailsPage ? spacing(2) : 0)};
  margin-bottom: ${({ isCheckDetailsPage }) => (isCheckDetailsPage ? 0 : undefined)};
  &&& {
    border: ${borderPx}px solid ${colors.neutral01};
  }
  .bullet {
    svg {
      fill: ${colors.core03};
    }
  }
`;

export const StyledFlexibleWidthDivider = styled.div`
  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(1)};
`;
export const StyledInfoPanel = styled(InfoPanel)<{
  isCheckDetailsPage: boolean;
}>`
  padding: ${spacing(3)};
  margin-top: ${({ isCheckDetailsPage }) => (isCheckDetailsPage ? spacing(2) : 0)};
  margin-bottom: ${({ isCheckDetailsPage }) => (isCheckDetailsPage ? 0 : undefined)};
  &&& {
    border: ${borderPx}px solid ${colors.neutral01};
    box-shadow: none;
    ul {
      padding-left: ${spacing(0)};
    }
    ${Grid} {
      padding: 0 ${spacing(2)};
    }
  }
`;

export const StyledPanelHeadingContainer = styled.div`
  display: flex;
`;

export const StyledPanelHeading = styled.p`
  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}
  && {
    font-weight: ${semiBoldFontWeight};
  }
  margin: 0 0 ${spacing(1)} 0;
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  ${mediaQuery.tabletLandscape`
    && p, li {
      ${fonts.paragraphLarge};
      & {
        font-size: 18px;
      }
    }
    && strong {
      font-weight: ${semiBoldFontWeight};
    }
    && p + p {
      margin-top: ${spacing(3)}
    }
  `}
`;
