import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import QuestionField from 'components/QuestionField';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useKeepingYouInformedQuestions from './questions';
import { KeepingYouInformedText, PrivacyNotice } from './styles';

type KeepingYouInformedFormDetails = Pick<FormDetails, 'keepInformed'>;

type KeepingYouInformedFormProps = {
  formValidation: {
    getError: FieldFunction<KeepingYouInformedFormDetails, string | undefined>;
    showValidation: FieldFunction<KeepingYouInformedFormDetails, void>;
  };
};

const query = graphql`
  query {
    csPetKeepingYouInformedQuestions {
      keep_informed_question {
        privacy_notice
      }
      keep_informed_text
    }
  }
`;

type csKeepingYouInformedForm = {
  csPetKeepingYouInformedQuestions: {
    keep_informed_question: {
      privacy_notice: string | undefined;
    };
    keep_informed_text: string;
  };
};

const KeepingYouInformedForm: React.FC<KeepingYouInformedFormProps> = ({
  formValidation,
}) => {
  const questions = useKeepingYouInformedQuestions();

  const [policyDetails, updatePolicyDetails] = usePolicyDetails();

  const { keep_informed_question, keep_informed_text } = useStaticQuery<
    csKeepingYouInformedForm
  >(query).csPetKeepingYouInformedQuestions;

  return (
    <Grid alignLeft>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        {keep_informed_text && (
          <KeepingYouInformedText
            pageTitle={PageTitle.AboutYouAndYourPet}
            html={keep_informed_text}
          />
        )}
        <QuestionField
          question={questions.keepingYouInformed}
          errorText={formValidation.getError('keepInformed')}>
          <BooleanRadioInput
            id="keepInformed"
            value={policyDetails.keepInformed}
            analyticsDescription="Keep informed"
            onChange={(value) => {
              updatePolicyDetails({ keepInformed: value });
            }}
            data-cy="keepInformedRadio"
          />
        </QuestionField>
        {keep_informed_question.privacy_notice && (
          <PrivacyNotice
            pageTitle={PageTitle.AboutYouAndYourPet}
            html={keep_informed_question.privacy_notice}
            onLinkClick={trackRichTextLinkClick(PageTitle.AboutYouAndYourPet)}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export default KeepingYouInformedForm;
