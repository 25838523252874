import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { usePetsDetails } from 'state/formData/petsDetails';
import { PolicyDetails } from 'state/formData/policyDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsKeepingYouInformedErrorMessages = {
  csPetKeepingYouInformedQuestions: {
    keep_informed_question: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csPetKeepingYouInformedQuestions {
      keep_informed_question {
        error_messages {
          missing
        }
      }
    }
  }
`;

const useKeepingYouInformedRules = (): ValidationRules<
  Pick<PolicyDetails, 'keepInformed'>
> => {
  const errorMessages = useStaticQuery<CsKeepingYouInformedErrorMessages>(query);
  const [petDetails] = usePetsDetails();

  const validateKeepInformedSection: boolean = petDetails.some(
    ({ petType, petName, petIsEligible, petInGoodHealth, petLivesWithYou }) =>
      (petType === petType_DOG && petInGoodHealth && petIsEligible && petName) ||
      (petType === petType_CAT && petInGoodHealth && petLivesWithYou && petName)
  );
  return {
    keepInformed: validateIf(() => validateKeepInformedSection, [
      required(
        errorMessages.csPetKeepingYouInformedQuestions.keep_informed_question
          .error_messages.missing
      ),
    ]),
  };
};

export default useKeepingYouInformedRules;
